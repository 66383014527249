<template scoped>
  <el-button
    class="delete"
    type="danger"
    icon="el-icon-delete"
    @click="deleteLogic(id)"
    circle
  ></el-button>
</template>

<script scoped>
export default {
  props: {
    id: String,
    deleteLogic: Function,
  },
};
</script>


<style scoped>
.delete {
  position: absolute;
  top: -15px;
  right: -15px;
}
</style>